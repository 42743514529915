import React, { createContext, useState } from 'react';

const ALERT_TIMEOUT = 5000;

enum AlertType {
  None,
  Success,
  Failure
}

interface AlertContextType {
  text: string | null,
  type: AlertType,
  success: (text: string) => void,
  failure: (text: string) => void,
  clear: () => void,
}

const DEFAULT_VALUES = {
  text: "",
  type: AlertType.None,
  success: (_text: string) => console.error("Success not implemented"),
  failure: (_text: string) => console.error("Error not implemented"),
  clear: () => console.error("Error not implemented")
}


let AlertContext = createContext<AlertContextType>(DEFAULT_VALUES);

export function AlertProvider({ children }: { children: React.ReactNode }) {
  const [type, setType] = useState(AlertType.None);
  const [text, setText] = useState<string | null>(null);

  const clearWithTimeout = () =>
    setTimeout(() => {
      setText(null);
    }, ALERT_TIMEOUT);

  return <AlertContext.Provider value={{
    text,
    type,
    success: (text: string) => {
      setText(text);
      setType(AlertType.Success);
      clearWithTimeout();
    },
    failure: (text: string) => {
      setText(text);
      setType(AlertType.Failure);
      clearWithTimeout();
    },
    clear: () => {
      setText(null);
      setType(AlertType.None);
    }
  }}>
    {children}
  </AlertContext.Provider>
};

export { AlertType };
export default AlertContext;
