import React from 'react';
import 'leaflet/dist/leaflet.css';
import { OnShipmentGpsEventAddedDocument, OnShipmentGpsEventAddedSubscription, OnShipmentGpsEventAddedSubscriptionVariables, Status, useShipmentsActiveQuery } from '../generated/graphql';
import Map from '../components/Map';
import { MARKER_PING } from '../constants';
import { LayerGroup, LayersControl, Marker, Popup, TileLayer } from 'react-leaflet';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

const ShipmentsActive: React.FC = (): JSX.Element => {
  const { subscribeToMore, refetch, loading, error, data } = useShipmentsActiveQuery({
    variables: {
      status: Status.InTransit,
      first: 1000
    }
  });

  if (loading || error || !data) {
    return <div>Loading...</div>;
  }

  subscribeToMore<OnShipmentGpsEventAddedSubscription, OnShipmentGpsEventAddedSubscriptionVariables>({
    document: OnShipmentGpsEventAddedDocument,
    updateQuery: (prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev;

      console.log("Refetching active shipments after GPS update...");
      refetch();
      console.log("subscription data", subscriptionData.data);

      return prev;
    }
  });

  return (
    <div className="h-full w-full">
      <Map center={[51.505, -0.09]} zoom={3}>
        <LayersControl position="topright">
          <LayersControl.Overlay name="Clouds">
            <LayerGroup>
              <TileLayer url="https://tile.openweathermap.org/map/clouds_new/{z}/{x}/{y}.png?appid=cc675ab17332aa112f2fe166e02c836b"></TileLayer>
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Precipitation">
            <LayerGroup>
              <TileLayer url="https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=cc675ab17332aa112f2fe166e02c836b"></TileLayer>
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Sea level pressure">
            <LayerGroup>
              <TileLayer url="https://tile.openweathermap.org/map/pressure_new/{z}/{x}/{y}.png?appid=cc675ab17332aa112f2fe166e02c836b"></TileLayer>
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Wind">
            <LayerGroup>
              <TileLayer url="https://tile.openweathermap.org/map/wind_new/{z}/{x}/{y}.png?appid=cc675ab17332aa112f2fe166e02c836b"></TileLayer>
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Temperature">
            <LayerGroup>
              <TileLayer url="https://tile.openweathermap.org/map/wind_new/{z}/{x}/{y}.png?appid=cc675ab17332aa112f2fe166e02c836b"></TileLayer>
            </LayerGroup>
          </LayersControl.Overlay>
        </LayersControl>
        {data.paginateShipments?.edges?.map((edge, index) => {
          const shipment = edge?.node;
          const latestGpsEvent = shipment?.latestGpsEvent;
          const geom = latestGpsEvent?.geom;
          if (geom?.__typename === 'Point') {
            const [lat, lng]: any = geom.coordinates;
            // TODO: refactoring
            return <Marker icon={MARKER_PING} position={[lng, lat]} key={index}>
              <Popup>
                <div>Current position of <NavLink to={`/shipments/${shipment?.id}?expanded=true`}>{shipment?.idInternal}</NavLink></div>
                <div className="border-b mb-2 pb-2">reported {moment(latestGpsEvent?.eventTime).fromNow()} at {latestGpsEvent?.eventTime}</div>
                <div><strong>Status Code:</strong> {latestGpsEvent?.statusCode}</div>
                {latestGpsEvent?.reasonCode && <div>{latestGpsEvent?.reasonCode}</div>}
              </Popup>
            </Marker>
          }
          return null;
        })}
      </Map>
    </div>
  );
}

export default ShipmentsActive;