import ShipmentListElement from "../components/ShipmentListElement";
import { useDashboardQuery } from "../generated/graphql";

const Dashboard: React.FC = (): JSX.Element => {
  const { loading, error, data } = useDashboardQuery({
    variables: {
      first: 50
    },
    fetchPolicy: "cache-and-network"
  });

  if (loading || error || !data) {
    return <div>Loading...</div>;
  }

  const nodes = data.favoriteShipments?.edges?.map((edge) => edge?.node);

  return <main className="flex-1">
    {/* Page title & actions */}
    <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
      <div className="min-w-0 flex-1">
        <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Dashboard</h1>
      </div>
    </div>

    <div className="mt-6 px-4 sm:px-6 lg:px-8">
      <h2 className="text-sm font-medium text-gray-900">Metrics</h2>
      <ul className="mt-3 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 xl:grid-cols-4">
        <MetricCard metric="Total Shipments" value={data.shipments?.count || 0} />
        <MetricCard metric="Shipments in transit" value={data.shipments_in_transit?.count || 0} />
        <MetricCard metric="Locations (Suppliers / RDCs / ...)" value={data.locations?.count || 0} />
      </ul>
    </div>

    {/* Favorite shipments */}
    <div className="mt-8 border-t pt-4">
      <div className="border-b border-gray-200">
        <h2 className="text-sm font-medium text-gray-900 px-4 sm:px-6 lg:px-8">Favorites</h2>
        {nodes && nodes.length > 0 ?
          <div className="relative mt-2">
            <ul className="relative z-0 divide-y divide-gray-200">
              {nodes?.map((node) =>
                node != null
                  ?
                  <li className="px-4 sm:px-6 lg:px-8" key={`dashboard-favorite-${node.id}`}>
                    <ShipmentListElement
                      shipment={node.shipment}
                    />
                  </li>
                  : null
              )}
            </ul>
          </div>
          :
          <div className="px-4 sm:px-6 lg:px-8 pb-4">
            <h3 className="mt-2 text-sm text-gray-900">No shipments marked as favorite yet.</h3>
          </div>
        }
      </div>
    </div>
  </main>
}

const MetricCard = (props: { metric: String, value: number }) =>
  <div className="overflow-hidden rounded-lg bg-white px-4 py-5 border shadow-sm sm:p-6">
    <dt className="truncate text-sm font-medium text-gray-500">{props.metric}</dt>
    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{props.value}</dd>
  </div>;

export default Dashboard;