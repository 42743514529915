import React, { FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline';
import { useAuth } from './Auth';
import { useLogoutMutation } from '../generated/graphql';
import { useApolloClient } from '@apollo/client';

const PATH_LOGIN = "/login";

const Logout: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const auth = useAuth();
  const client = useApolloClient();
  const [logoutMutation] = useLogoutMutation();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    logoutMutation({
      onCompleted: () => {
        client.clearStore();
        auth.signout(() => navigate(PATH_LOGIN))
      },
    });
  }

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <button type="submit"
        className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full"
      >
        <ArrowLeftOnRectangleIcon
          className="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6"
          aria-hidden="true"
        />
        Sign out
      </button>
    </form>
  )
}

export default Logout;