import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Json: any;
};

/** Generic aggregation object with `count`, `min`, and `max` values. */
export type Aggregate = {
  __typename?: 'Aggregate';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['String']>;
};

/** A carrier. */
export type Carrier = {
  __typename?: 'Carrier';
  id?: Maybe<Scalars['Int']>;
  idInternal?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ChangePasswordInput = {
  clientMutationId: Scalars['String'];
  currentPassword: Scalars['String'];
  id: Scalars['ID'];
  newPassword: Scalars['String'];
};

export type ChangePasswordPayload = {
  __typename?: 'ChangePasswordPayload';
  clientMutationId: Scalars['String'];
  user?: Maybe<UserPayload>;
};

export type CreateFavoriteShipmentInput = {
  clientMutationId: Scalars['String'];
  shipmentId: Scalars['ID'];
};

export type CreateFavoriteShipmentPayload = {
  __typename?: 'CreateFavoriteShipmentPayload';
  clientMutationId: Scalars['String'];
  favoriteShipment?: Maybe<FavoriteShipmentPayload>;
};

export type CreateUserInput = {
  clientMutationId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  clientMutationId: Scalars['String'];
  user?: Maybe<UserPayload>;
};

export type DeleteFavoriteShipmentInput = {
  clientMutationId: Scalars['String'];
  favoriteShipmentId: Scalars['ID'];
};

export type DeleteFavoriteShipmentPayload = {
  __typename?: 'DeleteFavoriteShipmentPayload';
  clientMutationId: Scalars['String'];
  favoriteShipment?: Maybe<FavoriteShipmentPayload>;
};

export type DeleteUserInput = {
  clientMutationId: Scalars['String'];
  id: Scalars['ID'];
};

export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  clientMutationId: Scalars['String'];
  user?: Maybe<UserPayload>;
};

/** A users favorite shipment. */
export type FavoriteShipment = Node & {
  __typename?: 'FavoriteShipment';
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['DateTime']>;
  shipment: Shipment;
  user: User;
};

export type FavoriteShipmentConnection = {
  __typename?: 'FavoriteShipmentConnection';
  edges?: Maybe<Array<Maybe<FavoriteShipmentEdge>>>;
  pageInfo: PageInfo;
};

export type FavoriteShipmentEdge = {
  __typename?: 'FavoriteShipmentEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<FavoriteShipment>;
};

export type FavoriteShipmentPayload = {
  __typename?: 'FavoriteShipmentPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<FavoriteShipment>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean'];
};

export type ForgotPasswordInput = {
  clientMutationId: Scalars['String'];
  email: Scalars['String'];
};

export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload';
  clientMutationId: Scalars['String'];
};

/** Union of all GeoJSON types. */
export type GeoJson = LineString | Point;

export type GeoObject = {
  type?: Maybe<Scalars['String']>;
};

/** A GPS event. */
export type GpsEvent = {
  __typename?: 'GpsEvent';
  eventTime?: Maybe<Scalars['DateTime']>;
  geom?: Maybe<GeoJson>;
  locationInformation?: Maybe<Scalars['Json']>;
  payload?: Maybe<Scalars['Json']>;
  reasonCode?: Maybe<ReasonCode>;
  shipment?: Maybe<Shipment>;
  statusCode?: Maybe<StatusCode>;
};

/** Geo object representing a line string. */
export type LineString = GeoObject & {
  __typename?: 'LineString';
  coordinates?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']>>>>>;
  type?: Maybe<Scalars['String']>;
};

/** A geographic location. */
export type Location = Node & {
  __typename?: 'Location';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  geom?: Maybe<GeoJson>;
  /** The ID of an object */
  id: Scalars['ID'];
  idInternal?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type LocationConnection = {
  __typename?: 'LocationConnection';
  edges?: Maybe<Array<Maybe<LocationEdge>>>;
  pageInfo: PageInfo;
};

export type LocationEdge = {
  __typename?: 'LocationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Location>;
};

export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** An order. */
export type Order = Node & {
  __typename?: 'Order';
  destination?: Maybe<Location>;
  endTimeEstimated?: Maybe<Scalars['DateTime']>;
  /** The ID of an object */
  id: Scalars['ID'];
  idInternal: Scalars['String'];
  origin?: Maybe<Location>;
  shipment?: Maybe<Shipment>;
  startTimeEstimated?: Maybe<Scalars['DateTime']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** Geo object representing a point. */
export type Point = GeoObject & {
  __typename?: 'Point';
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  type?: Maybe<Scalars['String']>;
};

export enum ReasonCode {
  Breakdown = 'BREAKDOWN',
  CarrierRelated = 'CARRIER_RELATED',
  ConsigneeRelated = 'CONSIGNEE_RELATED',
  CustomsDelay = 'CUSTOMS_DELAY',
  HolidayRelated = 'HOLIDAY_RELATED',
  NormalStatus = 'NORMAL_STATUS',
  RailroadFailed = 'RAILROAD_FAILED',
  ShipperRelated = 'SHIPPER_RELATED',
  WeatherRelated = 'WEATHER_RELATED'
}

export type ResetPasswordInput = {
  clientMutationId: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmed: Scalars['String'];
  token: Scalars['String'];
};

export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  clientMutationId: Scalars['String'];
  user?: Maybe<UserPayload>;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  changePassword?: Maybe<ChangePasswordPayload>;
  /** Creates a favorite shipment for the logged in user. */
  createFavoriteShipment?: Maybe<CreateFavoriteShipmentPayload>;
  createUser?: Maybe<CreateUserPayload>;
  /** Deletes a favorite shipment for the logged in user. */
  deleteFavoriteShipment?: Maybe<DeleteFavoriteShipmentPayload>;
  deleteUser?: Maybe<DeleteUserPayload>;
  forgotPassword?: Maybe<ForgotPasswordPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  /** Signs a user in. */
  signin?: Maybe<Session>;
  /** Signs a user out. */
  signout?: Maybe<Session>;
  updateUser?: Maybe<UpdateUserPayload>;
};


export type RootMutationTypeChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type RootMutationTypeCreateFavoriteShipmentArgs = {
  input: CreateFavoriteShipmentInput;
};


export type RootMutationTypeCreateUserArgs = {
  input: CreateUserInput;
};


export type RootMutationTypeDeleteFavoriteShipmentArgs = {
  input: DeleteFavoriteShipmentInput;
};


export type RootMutationTypeDeleteUserArgs = {
  input: DeleteUserInput;
};


export type RootMutationTypeForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type RootMutationTypeResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type RootMutationTypeSigninArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type RootMutationTypeUpdateUserArgs = {
  input: UpdateUserInput;
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** Aggregation of locations. */
  aggregateLocations?: Maybe<Aggregate>;
  /** Aggregation of shipments. */
  aggregateShipments?: Maybe<Aggregate>;
  /** Retrieve all carriers. */
  carriers?: Maybe<Array<Maybe<Carrier>>>;
  /** Paginate favorite shipments for the logged in user. */
  favoriteShipments?: Maybe<FavoriteShipmentConnection>;
  /** Retrieve all GPS events. */
  gpsEvents?: Maybe<Array<Maybe<GpsEvent>>>;
  /** Retrieve all locations. */
  locations?: Maybe<Array<Maybe<Location>>>;
  /** Retrieve currently logged in user. */
  me?: Maybe<User>;
  node?: Maybe<Node>;
  /** Paginate locations. */
  paginateLocations?: Maybe<LocationConnection>;
  /** Paginate all shipments. Optionally filter by `status`, `filter`, `origin`, or `destination`. */
  paginateShipments?: Maybe<ShipmentConnection>;
  /** Get a shipment by `id` or `idInternal`. */
  shipment?: Maybe<Shipment>;
  /** Retrieve all shipments. Optionally filter by `status`. */
  shipments?: Maybe<Array<Maybe<Shipment>>>;
  /** Get a user by `id`. */
  user?: Maybe<User>;
  /** Paginate users. */
  users?: Maybe<UserConnection>;
};


export type RootQueryTypeAggregateLocationsArgs = {
  field: Scalars['String'];
  filterField?: InputMaybe<Scalars['String']>;
  filterValue?: InputMaybe<Scalars['String']>;
};


export type RootQueryTypeAggregateShipmentsArgs = {
  field: Scalars['String'];
  filterField?: InputMaybe<Scalars['String']>;
  filterValue?: InputMaybe<Scalars['String']>;
};


export type RootQueryTypeFavoriteShipmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type RootQueryTypeNodeArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypePaginateLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type RootQueryTypePaginateShipmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  destination?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  origin?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
};


export type RootQueryTypeShipmentArgs = {
  id?: InputMaybe<Scalars['String']>;
  idInternal?: InputMaybe<Scalars['String']>;
};


export type RootQueryTypeShipmentsArgs = {
  status?: InputMaybe<Status>;
};


export type RootQueryTypeUserArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type RootQueryTypeUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  gpsEventAdded?: Maybe<GpsEvent>;
};


export type RootSubscriptionTypeGpsEventAddedArgs = {
  shipmentId?: InputMaybe<Scalars['String']>;
};

export type Session = {
  __typename?: 'Session';
  token: Scalars['String'];
  user: User;
};

/** A shipment. */
export type Shipment = Node & {
  __typename?: 'Shipment';
  carrier?: Maybe<Carrier>;
  endTimeActual?: Maybe<Scalars['DateTime']>;
  eta?: Maybe<ShipmentEta>;
  events?: Maybe<Array<Maybe<GpsEvent>>>;
  /** Is this shipment a favorite for the logged in user */
  favorite?: Maybe<FavoriteShipment>;
  gpsEvents?: Maybe<Array<Maybe<GpsEvent>>>;
  /** The ID of an object */
  id: Scalars['ID'];
  idInternal: Scalars['String'];
  latestGpsEvent?: Maybe<GpsEvent>;
  orders?: Maybe<Array<Maybe<Order>>>;
  startTimeActual?: Maybe<Scalars['DateTime']>;
  status: Status;
  track?: Maybe<LineString>;
};

export type ShipmentConnection = {
  __typename?: 'ShipmentConnection';
  edges?: Maybe<Array<Maybe<ShipmentEdge>>>;
  pageInfo: PageInfo;
};

export type ShipmentEdge = {
  __typename?: 'ShipmentEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Shipment>;
};

/** A shipment ETA. */
export type ShipmentEta = {
  __typename?: 'ShipmentEta';
  estimatedArrivalTime: Scalars['DateTime'];
  eventTime: Scalars['DateTime'];
  id: Scalars['Int'];
  sourceReference?: Maybe<Scalars['Int']>;
  sourceReferenceType?: Maybe<Scalars['String']>;
  updateCount: Scalars['Int'];
};

/** The status of a shipment. */
export enum Status {
  /** Shipment delivered */
  Delivered = 'DELIVERED',
  /** Shipment initialized */
  Initial = 'INITIAL',
  /** Shipment in transit */
  InTransit = 'IN_TRANSIT'
}

export enum StatusCode {
  AtDelivery = 'AT_DELIVERY',
  AtShipper = 'AT_SHIPPER',
  DepartedFromDeliveryLocation = 'DEPARTED_FROM_DELIVERY_LOCATION',
  Dispatched = 'DISPATCHED',
  InTransit = 'IN_TRANSIT',
  LocationUpdate = 'LOCATION_UPDATE'
}

export type UpdateUserInput = {
  clientMutationId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  clientMutationId: Scalars['String'];
  user?: Maybe<UserPayload>;
};

/** A user. */
export type User = Node & {
  __typename?: 'User';
  confirmedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  pageInfo: PageInfo;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<User>;
};

export type UserPayload = {
  __typename?: 'UserPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<User>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean'];
};

/**
 * Validation messages are returned when mutation input does not meet the requirements.
 *   While client-side validation is highly recommended to provide the best User Experience,
 *   All inputs will always be validated server-side.
 *
 *   Some examples of validations are:
 *
 *   * Username must be at least 10 characters
 *   * Email field does not contain an email address
 *   * Birth Date is required
 *
 *   While GraphQL has support for required values, mutation data fields are always
 *   set to optional in our API. This allows 'required field' messages
 *   to be returned in the same manner as other validations. The only exceptions
 *   are id fields, which may be required to perform updates or deletes.
 */
export type ValidationMessage = {
  __typename?: 'ValidationMessage';
  /** A unique error code for the type of validation used. */
  code: Scalars['String'];
  /**
   * The input field that the error applies to. The field can be used to
   * identify which field the error message should be displayed next to in the
   * presentation layer.
   *
   * If there are multiple errors to display for a field, multiple validation
   * messages will be in the result.
   *
   * This field may be null in cases where an error cannot be applied to a specific field.
   */
  field?: Maybe<Scalars['String']>;
  /**
   * A friendly error message, appropriate for display to the end user.
   *
   * The message is interpolated to include the appropriate variables.
   *
   * Example: `Username must be at least 10 characters`
   *
   * This message may change without notice, so we do not recommend you match against the text.
   * Instead, use the *code* field for matching.
   */
  message?: Maybe<Scalars['String']>;
  /** A list of substitutions to be applied to a validation message template */
  options?: Maybe<Array<Maybe<ValidationOption>>>;
  /**
   * A template used to generate the error message, with placeholders for option substiution.
   *
   * Example: `Username must be at least {count} characters`
   *
   * This message may change without notice, so we do not recommend you match against the text.
   * Instead, use the *code* field for matching.
   */
  template?: Maybe<Scalars['String']>;
};

export type ValidationOption = {
  __typename?: 'ValidationOption';
  /** The name of a variable to be subsituted in a validation message template */
  key: Scalars['String'];
  /** The value of a variable to be substituted in a validation message template */
  value: Scalars['String'];
};

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'RootQueryType', me?: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string } | null };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'RootMutationType', signin?: { __typename?: 'Session', token: string, user: { __typename?: 'User', email: string, firstName: string, lastName: string } } | null };

export type ForgotPasswordMutationVariables = Exact<{
  clientMutationId: Scalars['String'];
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { __typename?: 'RootMutationType', forgotPassword?: { __typename?: 'ForgotPasswordPayload', clientMutationId: string } | null };

export type ChangePasswordMutationVariables = Exact<{
  clientMutationId: Scalars['String'];
  id: Scalars['ID'];
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = { __typename?: 'RootMutationType', changePassword?: { __typename?: 'ChangePasswordPayload', clientMutationId: string, user?: { __typename?: 'UserPayload', successful: boolean, messages?: Array<{ __typename?: 'ValidationMessage', code: string, field?: string | null, message?: string | null } | null> | null } | null } | null };

export type ResetPasswordMutationVariables = Exact<{
  clientMutationId: Scalars['String'];
  token: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmed: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'RootMutationType', resetPassword?: { __typename?: 'ResetPasswordPayload', clientMutationId: string, user?: { __typename?: 'UserPayload', successful: boolean, result?: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string, updatedAt?: any | null } | null, messages?: Array<{ __typename?: 'ValidationMessage', code: string, field?: string | null, message?: string | null } | null> | null } | null } | null };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'RootMutationType', signout?: { __typename?: 'Session', user: { __typename?: 'User', email: string, firstName: string, lastName: string } } | null };

export type GpsEventWithGeomFragment = { __typename?: 'GpsEvent', geom?: { __typename?: 'LineString' } | { __typename?: 'Point', coordinates?: Array<number | null> | null } | null };

export type ShipmentWithCarrierFragment = { __typename?: 'Shipment', carrier?: { __typename?: 'Carrier', id?: number | null, idInternal?: string | null, name?: string | null } | null };

export type ShipmentWithFavoriteFragment = { __typename?: 'Shipment', favorite?: { __typename?: 'FavoriteShipment', id: string, insertedAt?: any | null } | null };

export type ShipmentListItemFragment = { __typename?: 'Shipment', id: string, idInternal: string, startTimeActual?: any | null, endTimeActual?: any | null, status: Status, carrier?: { __typename?: 'Carrier', id?: number | null, idInternal?: string | null, name?: string | null } | null, orders?: Array<{ __typename?: 'Order', id: string, idInternal: string, startTimeEstimated?: any | null, endTimeEstimated?: any | null, origin?: { __typename?: 'Location', id: string, idInternal?: string | null, name?: string | null, type?: string | null, geom?: { __typename?: 'LineString' } | { __typename?: 'Point', coordinates?: Array<number | null> | null } | null } | null, destination?: { __typename?: 'Location', id: string, idInternal?: string | null, name?: string | null, type?: string | null, geom?: { __typename?: 'LineString' } | { __typename?: 'Point', coordinates?: Array<number | null> | null } | null } | null } | null> | null, favorite?: { __typename?: 'FavoriteShipment', id: string, insertedAt?: any | null } | null, eta?: { __typename?: 'ShipmentEta', estimatedArrivalTime: any, updateCount: number, eventTime: any, sourceReference?: number | null, sourceReferenceType?: string | null } | null };

export type ShipmentWithOrderFragment = { __typename?: 'Shipment', orders?: Array<{ __typename?: 'Order', id: string, idInternal: string, startTimeEstimated?: any | null, endTimeEstimated?: any | null, origin?: { __typename?: 'Location', id: string, idInternal?: string | null, name?: string | null, type?: string | null, geom?: { __typename?: 'LineString' } | { __typename?: 'Point', coordinates?: Array<number | null> | null } | null } | null, destination?: { __typename?: 'Location', id: string, idInternal?: string | null, name?: string | null, type?: string | null, geom?: { __typename?: 'LineString' } | { __typename?: 'Point', coordinates?: Array<number | null> | null } | null } | null } | null> | null };

export type ShipmentWithEtaFragment = { __typename?: 'Shipment', eta?: { __typename?: 'ShipmentEta', estimatedArrivalTime: any, updateCount: number, eventTime: any, sourceReference?: number | null, sourceReferenceType?: string | null } | null };

export type PageInfoFragment = { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean };

export type ShipmentsQueryVariables = Exact<{
  filter?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  origin?: InputMaybe<Scalars['String']>;
  destination?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;


export type ShipmentsQuery = { __typename?: 'RootQueryType', paginateShipments?: { __typename?: 'ShipmentConnection', edges?: Array<{ __typename?: 'ShipmentEdge', node?: { __typename?: 'Shipment', id: string, idInternal: string, startTimeActual?: any | null, endTimeActual?: any | null, status: Status, carrier?: { __typename?: 'Carrier', id?: number | null, idInternal?: string | null, name?: string | null } | null, orders?: Array<{ __typename?: 'Order', id: string, idInternal: string, startTimeEstimated?: any | null, endTimeEstimated?: any | null, origin?: { __typename?: 'Location', id: string, idInternal?: string | null, name?: string | null, type?: string | null, geom?: { __typename?: 'LineString' } | { __typename?: 'Point', coordinates?: Array<number | null> | null } | null } | null, destination?: { __typename?: 'Location', id: string, idInternal?: string | null, name?: string | null, type?: string | null, geom?: { __typename?: 'LineString' } | { __typename?: 'Point', coordinates?: Array<number | null> | null } | null } | null } | null> | null, favorite?: { __typename?: 'FavoriteShipment', id: string, insertedAt?: any | null } | null, eta?: { __typename?: 'ShipmentEta', estimatedArrivalTime: any, updateCount: number, eventTime: any, sourceReference?: number | null, sourceReferenceType?: string | null } | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type DashboardQueryVariables = Exact<{
  first: Scalars['Int'];
}>;


export type DashboardQuery = { __typename?: 'RootQueryType', locations?: { __typename?: 'Aggregate', count?: number | null } | null, shipments?: { __typename?: 'Aggregate', count?: number | null } | null, shipments_in_transit?: { __typename?: 'Aggregate', count?: number | null } | null, favoriteShipments?: { __typename?: 'FavoriteShipmentConnection', edges?: Array<{ __typename?: 'FavoriteShipmentEdge', node?: { __typename?: 'FavoriteShipment', id: string, insertedAt?: any | null, shipment: { __typename?: 'Shipment', id: string, idInternal: string, startTimeActual?: any | null, endTimeActual?: any | null, status: Status, carrier?: { __typename?: 'Carrier', id?: number | null, idInternal?: string | null, name?: string | null } | null, orders?: Array<{ __typename?: 'Order', id: string, idInternal: string, startTimeEstimated?: any | null, endTimeEstimated?: any | null, origin?: { __typename?: 'Location', id: string, idInternal?: string | null, name?: string | null, type?: string | null, geom?: { __typename?: 'LineString' } | { __typename?: 'Point', coordinates?: Array<number | null> | null } | null } | null, destination?: { __typename?: 'Location', id: string, idInternal?: string | null, name?: string | null, type?: string | null, geom?: { __typename?: 'LineString' } | { __typename?: 'Point', coordinates?: Array<number | null> | null } | null } | null } | null> | null, favorite?: { __typename?: 'FavoriteShipment', id: string, insertedAt?: any | null } | null, eta?: { __typename?: 'ShipmentEta', estimatedArrivalTime: any, updateCount: number, eventTime: any, sourceReference?: number | null, sourceReferenceType?: string | null } | null } } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type CreateFavoriteShipmentMutationVariables = Exact<{
  clientMutationId: Scalars['String'];
  shipmentId: Scalars['ID'];
}>;


export type CreateFavoriteShipmentMutation = { __typename?: 'RootMutationType', createFavoriteShipment?: { __typename?: 'CreateFavoriteShipmentPayload', clientMutationId: string, favoriteShipment?: { __typename?: 'FavoriteShipmentPayload', successful: boolean, messages?: Array<{ __typename?: 'ValidationMessage', field?: string | null, code: string, message?: string | null } | null> | null } | null } | null };

export type DeleteFavoriteShipmentMutationVariables = Exact<{
  clientMutationId: Scalars['String'];
  favoriteShipmentId: Scalars['ID'];
}>;


export type DeleteFavoriteShipmentMutation = { __typename?: 'RootMutationType', deleteFavoriteShipment?: { __typename?: 'DeleteFavoriteShipmentPayload', clientMutationId: string, favoriteShipment?: { __typename?: 'FavoriteShipmentPayload', successful: boolean, messages?: Array<{ __typename?: 'ValidationMessage', field?: string | null, code: string, message?: string | null } | null> | null, result?: { __typename?: 'FavoriteShipment', id: string } | null } | null } | null };

export type AggregateLocationsCountQueryVariables = Exact<{
  field: Scalars['String'];
  filterField?: InputMaybe<Scalars['String']>;
  filterValue?: InputMaybe<Scalars['String']>;
}>;


export type AggregateLocationsCountQuery = { __typename?: 'RootQueryType', aggregateLocations?: { __typename?: 'Aggregate', count?: number | null } | null };

export type ShipmentsActiveQueryVariables = Exact<{
  filter?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  first: Scalars['Int'];
}>;


export type ShipmentsActiveQuery = { __typename?: 'RootQueryType', paginateShipments?: { __typename?: 'ShipmentConnection', edges?: Array<{ __typename?: 'ShipmentEdge', node?: { __typename?: 'Shipment', id: string, idInternal: string, latestGpsEvent?: { __typename?: 'GpsEvent', eventTime?: any | null, reasonCode?: ReasonCode | null, statusCode?: StatusCode | null, locationInformation?: any | null, geom?: { __typename?: 'LineString' } | { __typename?: 'Point', coordinates?: Array<number | null> | null } | null } | null } | null } | null> | null } | null };

export type ShipmentQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;


export type ShipmentQuery = { __typename?: 'RootQueryType', shipment?: { __typename?: 'Shipment', id: string, idInternal: string, startTimeActual?: any | null, endTimeActual?: any | null, status: Status, carrier?: { __typename?: 'Carrier', id?: number | null, idInternal?: string | null, name?: string | null } | null, geometry?: { __typename?: 'LineString', coordinates?: Array<Array<number | null> | null> | null, type?: string | null } | null, events?: Array<{ __typename?: 'GpsEvent', eventTime?: any | null, reasonCode?: ReasonCode | null, locationInformation?: any | null, statusCode?: StatusCode | null, payload?: any | null, geom?: { __typename?: 'LineString' } | { __typename?: 'Point', coordinates?: Array<number | null> | null } | null } | null> | null, orders?: Array<{ __typename?: 'Order', id: string, idInternal: string, startTimeEstimated?: any | null, endTimeEstimated?: any | null, origin?: { __typename?: 'Location', id: string, idInternal?: string | null, name?: string | null, type?: string | null, geom?: { __typename?: 'LineString' } | { __typename?: 'Point', coordinates?: Array<number | null> | null } | null } | null, destination?: { __typename?: 'Location', id: string, idInternal?: string | null, name?: string | null, type?: string | null, geom?: { __typename?: 'LineString' } | { __typename?: 'Point', coordinates?: Array<number | null> | null } | null } | null } | null> | null, eta?: { __typename?: 'ShipmentEta', estimatedArrivalTime: any, updateCount: number, eventTime: any, sourceReference?: number | null, sourceReferenceType?: string | null } | null } | null };

export type UserFragment = { __typename?: 'User', id: string, firstName: string, lastName: string, email: string, confirmedAt?: any | null, insertedAt?: any | null, updatedAt?: any | null };

export type UsersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type UsersQuery = { __typename?: 'RootQueryType', users?: { __typename?: 'UserConnection', edges?: Array<{ __typename?: 'UserEdge', node?: { __typename?: 'User', id: string, firstName: string, lastName: string, email: string, confirmedAt?: any | null, insertedAt?: any | null, updatedAt?: any | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type UserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type UserQuery = { __typename?: 'RootQueryType', user?: { __typename?: 'User', id: string, firstName: string, lastName: string, email: string, confirmedAt?: any | null, insertedAt?: any | null, updatedAt?: any | null } | null };

export type CreateUserMutationVariables = Exact<{
  clientMutationId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type CreateUserMutation = { __typename?: 'RootMutationType', createUser?: { __typename?: 'CreateUserPayload', clientMutationId: string, user?: { __typename?: 'UserPayload', successful: boolean, result?: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string } | null, messages?: Array<{ __typename?: 'ValidationMessage', code: string, field?: string | null, message?: string | null } | null> | null } | null } | null };

export type UpdateUserMutationVariables = Exact<{
  clientMutationId: Scalars['String'];
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
}>;


export type UpdateUserMutation = { __typename?: 'RootMutationType', updateUser?: { __typename?: 'UpdateUserPayload', clientMutationId: string, user?: { __typename?: 'UserPayload', successful: boolean, result?: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string, updatedAt?: any | null } | null, messages?: Array<{ __typename?: 'ValidationMessage', code: string, field?: string | null, message?: string | null } | null> | null } | null } | null };

export type DeleteUserMutationVariables = Exact<{
  clientMutationId: Scalars['String'];
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = { __typename?: 'RootMutationType', deleteUser?: { __typename?: 'DeleteUserPayload', user?: { __typename?: 'UserPayload', successful: boolean, result?: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string } | null, messages?: Array<{ __typename?: 'ValidationMessage', code: string, field?: string | null, message?: string | null } | null> | null } | null } | null };

export type OnShipmentGpsEventAddedSubscriptionVariables = Exact<{
  shipmentId?: InputMaybe<Scalars['String']>;
}>;


export type OnShipmentGpsEventAddedSubscription = { __typename?: 'RootSubscriptionType', gpsEventAdded?: { __typename?: 'GpsEvent', eventTime?: any | null, geom?: { __typename?: 'LineString' } | { __typename?: 'Point', coordinates?: Array<number | null> | null, type?: string | null } | null, shipment?: { __typename?: 'Shipment', id: string, idInternal: string, status: Status } | null } | null };

export const GpsEventWithGeomFragmentDoc = gql`
    fragment GpsEventWithGeom on GpsEvent {
  geom {
    ... on Point {
      coordinates
    }
  }
}
    `;
export const ShipmentWithCarrierFragmentDoc = gql`
    fragment ShipmentWithCarrier on Shipment {
  carrier {
    id
    idInternal
    name
  }
}
    `;
export const ShipmentWithOrderFragmentDoc = gql`
    fragment ShipmentWithOrder on Shipment {
  orders {
    id
    idInternal
    origin {
      id
      idInternal
      name
      type
      geom {
        ... on Point {
          coordinates
        }
      }
    }
    destination {
      id
      idInternal
      name
      type
      geom {
        ... on Point {
          coordinates
        }
      }
    }
    startTimeEstimated
    endTimeEstimated
  }
}
    `;
export const ShipmentWithFavoriteFragmentDoc = gql`
    fragment ShipmentWithFavorite on Shipment {
  favorite {
    id
    insertedAt
  }
}
    `;
export const ShipmentWithEtaFragmentDoc = gql`
    fragment ShipmentWithETA on Shipment {
  eta {
    estimatedArrivalTime
    updateCount
    eventTime
    sourceReference
    sourceReferenceType
  }
}
    `;
export const ShipmentListItemFragmentDoc = gql`
    fragment ShipmentListItem on Shipment {
  id
  idInternal
  startTimeActual
  endTimeActual
  status
  ...ShipmentWithCarrier
  ...ShipmentWithOrder
  ...ShipmentWithFavorite
  ...ShipmentWithETA
}
    ${ShipmentWithCarrierFragmentDoc}
${ShipmentWithOrderFragmentDoc}
${ShipmentWithFavoriteFragmentDoc}
${ShipmentWithEtaFragmentDoc}`;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  startCursor
  endCursor
  hasNextPage
  hasPreviousPage
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  firstName
  lastName
  email
  confirmedAt
  insertedAt
  updatedAt
}
    `;
export const GetUserDocument = gql`
    query GetUser {
  me {
    id
    email
    firstName
    lastName
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  signin(email: $email, password: $password) {
    token
    user {
      email
      firstName
      lastName
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($clientMutationId: String!, $email: String!) {
  forgotPassword(input: {clientMutationId: $clientMutationId, email: $email}) {
    clientMutationId
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($clientMutationId: String!, $id: ID!, $currentPassword: String!, $newPassword: String!) {
  changePassword(
    input: {clientMutationId: $clientMutationId, currentPassword: $currentPassword, newPassword: $newPassword, id: $id}
  ) {
    clientMutationId
    user {
      messages {
        code
        field
        message
      }
      successful
    }
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *      id: // value for 'id'
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($clientMutationId: String!, $token: String!, $password: String!, $passwordConfirmed: String!) {
  resetPassword(
    input: {clientMutationId: $clientMutationId, token: $token, password: $password, passwordConfirmed: $passwordConfirmed}
  ) {
    clientMutationId
    user {
      successful
      result {
        id
        email
        firstName
        lastName
        updatedAt
      }
      messages {
        code
        field
        message
      }
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *      token: // value for 'token'
 *      password: // value for 'password'
 *      passwordConfirmed: // value for 'passwordConfirmed'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  signout {
    user {
      email
      firstName
      lastName
    }
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ShipmentsDocument = gql`
    query Shipments($filter: String, $status: Status, $origin: String, $destination: String, $after: String, $first: Int = 10) {
  paginateShipments(
    filter: $filter
    status: $status
    origin: $origin
    destination: $destination
    after: $after
    first: $first
  ) {
    edges {
      node {
        ...ShipmentListItem
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${ShipmentListItemFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useShipmentsQuery__
 *
 * To run a query within a React component, call `useShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      status: // value for 'status'
 *      origin: // value for 'origin'
 *      destination: // value for 'destination'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useShipmentsQuery(baseOptions?: Apollo.QueryHookOptions<ShipmentsQuery, ShipmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShipmentsQuery, ShipmentsQueryVariables>(ShipmentsDocument, options);
      }
export function useShipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipmentsQuery, ShipmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShipmentsQuery, ShipmentsQueryVariables>(ShipmentsDocument, options);
        }
export type ShipmentsQueryHookResult = ReturnType<typeof useShipmentsQuery>;
export type ShipmentsLazyQueryHookResult = ReturnType<typeof useShipmentsLazyQuery>;
export type ShipmentsQueryResult = Apollo.QueryResult<ShipmentsQuery, ShipmentsQueryVariables>;
export const DashboardDocument = gql`
    query Dashboard($first: Int!) {
  locations: aggregateLocations(field: "id") {
    count
  }
  shipments: aggregateShipments(field: "id") {
    count
  }
  shipments_in_transit: aggregateShipments(
    field: "id"
    filterField: "status"
    filterValue: "in_transit"
  ) {
    count
  }
  favoriteShipments(first: $first) {
    edges {
      node {
        id
        insertedAt
        shipment {
          ...ShipmentListItem
        }
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${ShipmentListItemFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useDashboardQuery(baseOptions: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
      }
export function useDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardQueryResult = Apollo.QueryResult<DashboardQuery, DashboardQueryVariables>;
export const CreateFavoriteShipmentDocument = gql`
    mutation CreateFavoriteShipment($clientMutationId: String!, $shipmentId: ID!) {
  createFavoriteShipment(
    input: {clientMutationId: $clientMutationId, shipmentId: $shipmentId}
  ) {
    clientMutationId
    favoriteShipment {
      messages {
        field
        code
        message
      }
      successful
    }
  }
}
    `;
export type CreateFavoriteShipmentMutationFn = Apollo.MutationFunction<CreateFavoriteShipmentMutation, CreateFavoriteShipmentMutationVariables>;

/**
 * __useCreateFavoriteShipmentMutation__
 *
 * To run a mutation, you first call `useCreateFavoriteShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFavoriteShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFavoriteShipmentMutation, { data, loading, error }] = useCreateFavoriteShipmentMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *      shipmentId: // value for 'shipmentId'
 *   },
 * });
 */
export function useCreateFavoriteShipmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateFavoriteShipmentMutation, CreateFavoriteShipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFavoriteShipmentMutation, CreateFavoriteShipmentMutationVariables>(CreateFavoriteShipmentDocument, options);
      }
export type CreateFavoriteShipmentMutationHookResult = ReturnType<typeof useCreateFavoriteShipmentMutation>;
export type CreateFavoriteShipmentMutationResult = Apollo.MutationResult<CreateFavoriteShipmentMutation>;
export type CreateFavoriteShipmentMutationOptions = Apollo.BaseMutationOptions<CreateFavoriteShipmentMutation, CreateFavoriteShipmentMutationVariables>;
export const DeleteFavoriteShipmentDocument = gql`
    mutation DeleteFavoriteShipment($clientMutationId: String!, $favoriteShipmentId: ID!) {
  deleteFavoriteShipment(
    input: {clientMutationId: $clientMutationId, favoriteShipmentId: $favoriteShipmentId}
  ) {
    clientMutationId
    favoriteShipment {
      messages {
        field
        code
        message
      }
      successful
      result {
        id
      }
    }
  }
}
    `;
export type DeleteFavoriteShipmentMutationFn = Apollo.MutationFunction<DeleteFavoriteShipmentMutation, DeleteFavoriteShipmentMutationVariables>;

/**
 * __useDeleteFavoriteShipmentMutation__
 *
 * To run a mutation, you first call `useDeleteFavoriteShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFavoriteShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFavoriteShipmentMutation, { data, loading, error }] = useDeleteFavoriteShipmentMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *      favoriteShipmentId: // value for 'favoriteShipmentId'
 *   },
 * });
 */
export function useDeleteFavoriteShipmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFavoriteShipmentMutation, DeleteFavoriteShipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFavoriteShipmentMutation, DeleteFavoriteShipmentMutationVariables>(DeleteFavoriteShipmentDocument, options);
      }
export type DeleteFavoriteShipmentMutationHookResult = ReturnType<typeof useDeleteFavoriteShipmentMutation>;
export type DeleteFavoriteShipmentMutationResult = Apollo.MutationResult<DeleteFavoriteShipmentMutation>;
export type DeleteFavoriteShipmentMutationOptions = Apollo.BaseMutationOptions<DeleteFavoriteShipmentMutation, DeleteFavoriteShipmentMutationVariables>;
export const AggregateLocationsCountDocument = gql`
    query AggregateLocationsCount($field: String!, $filterField: String, $filterValue: String) {
  aggregateLocations(
    field: $field
    filterField: $filterField
    filterValue: $filterValue
  ) {
    count
  }
}
    `;

/**
 * __useAggregateLocationsCountQuery__
 *
 * To run a query within a React component, call `useAggregateLocationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateLocationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateLocationsCountQuery({
 *   variables: {
 *      field: // value for 'field'
 *      filterField: // value for 'filterField'
 *      filterValue: // value for 'filterValue'
 *   },
 * });
 */
export function useAggregateLocationsCountQuery(baseOptions: Apollo.QueryHookOptions<AggregateLocationsCountQuery, AggregateLocationsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateLocationsCountQuery, AggregateLocationsCountQueryVariables>(AggregateLocationsCountDocument, options);
      }
export function useAggregateLocationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateLocationsCountQuery, AggregateLocationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateLocationsCountQuery, AggregateLocationsCountQueryVariables>(AggregateLocationsCountDocument, options);
        }
export type AggregateLocationsCountQueryHookResult = ReturnType<typeof useAggregateLocationsCountQuery>;
export type AggregateLocationsCountLazyQueryHookResult = ReturnType<typeof useAggregateLocationsCountLazyQuery>;
export type AggregateLocationsCountQueryResult = Apollo.QueryResult<AggregateLocationsCountQuery, AggregateLocationsCountQueryVariables>;
export const ShipmentsActiveDocument = gql`
    query ShipmentsActive($filter: String, $status: Status, $first: Int!) {
  paginateShipments(filter: $filter, status: $status, first: $first) {
    edges {
      node {
        id
        idInternal
        latestGpsEvent {
          eventTime
          reasonCode
          statusCode
          locationInformation
          ...GpsEventWithGeom
        }
      }
    }
  }
}
    ${GpsEventWithGeomFragmentDoc}`;

/**
 * __useShipmentsActiveQuery__
 *
 * To run a query within a React component, call `useShipmentsActiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentsActiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentsActiveQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      status: // value for 'status'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useShipmentsActiveQuery(baseOptions: Apollo.QueryHookOptions<ShipmentsActiveQuery, ShipmentsActiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShipmentsActiveQuery, ShipmentsActiveQueryVariables>(ShipmentsActiveDocument, options);
      }
export function useShipmentsActiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipmentsActiveQuery, ShipmentsActiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShipmentsActiveQuery, ShipmentsActiveQueryVariables>(ShipmentsActiveDocument, options);
        }
export type ShipmentsActiveQueryHookResult = ReturnType<typeof useShipmentsActiveQuery>;
export type ShipmentsActiveLazyQueryHookResult = ReturnType<typeof useShipmentsActiveLazyQuery>;
export type ShipmentsActiveQueryResult = Apollo.QueryResult<ShipmentsActiveQuery, ShipmentsActiveQueryVariables>;
export const ShipmentDocument = gql`
    query Shipment($id: String) {
  shipment(id: $id) {
    id
    idInternal
    startTimeActual
    endTimeActual
    carrier {
      id
      idInternal
      name
    }
    status
    geometry: track {
      coordinates
      type
    }
    events {
      eventTime
      reasonCode
      locationInformation
      statusCode
      geom {
        ... on Point {
          coordinates
        }
      }
      payload
    }
    ...ShipmentWithOrder
    ...ShipmentWithETA
  }
}
    ${ShipmentWithOrderFragmentDoc}
${ShipmentWithEtaFragmentDoc}`;

/**
 * __useShipmentQuery__
 *
 * To run a query within a React component, call `useShipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShipmentQuery(baseOptions?: Apollo.QueryHookOptions<ShipmentQuery, ShipmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShipmentQuery, ShipmentQueryVariables>(ShipmentDocument, options);
      }
export function useShipmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipmentQuery, ShipmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShipmentQuery, ShipmentQueryVariables>(ShipmentDocument, options);
        }
export type ShipmentQueryHookResult = ReturnType<typeof useShipmentQuery>;
export type ShipmentLazyQueryHookResult = ReturnType<typeof useShipmentLazyQuery>;
export type ShipmentQueryResult = Apollo.QueryResult<ShipmentQuery, ShipmentQueryVariables>;
export const UsersDocument = gql`
    query Users($first: Int = 10, $after: String) {
  users(first: $first, after: $after) {
    edges {
      node {
        ...User
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${UserFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserDocument = gql`
    query User($id: String!) {
  user(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($clientMutationId: String!, $firstName: String!, $lastName: String!, $email: String!, $password: String!) {
  createUser(
    input: {clientMutationId: $clientMutationId, firstName: $firstName, lastName: $lastName, email: $email, password: $password}
  ) {
    clientMutationId
    user {
      successful
      result {
        id
        email
        firstName
        lastName
      }
      messages {
        code
        field
        message
      }
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($clientMutationId: String!, $id: ID!, $firstName: String!, $lastName: String!, $email: String!) {
  updateUser(
    input: {clientMutationId: $clientMutationId, id: $id, firstName: $firstName, lastName: $lastName, email: $email}
  ) {
    clientMutationId
    user {
      successful
      result {
        id
        email
        firstName
        lastName
        updatedAt
      }
      messages {
        code
        field
        message
      }
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($clientMutationId: String!, $id: ID!) {
  deleteUser(input: {clientMutationId: $clientMutationId, id: $id}) {
    user {
      successful
      result {
        id
        email
        firstName
        lastName
      }
      messages {
        code
        field
        message
      }
    }
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const OnShipmentGpsEventAddedDocument = gql`
    subscription OnShipmentGpsEventAdded($shipmentId: String) {
  gpsEventAdded(shipmentId: $shipmentId) {
    eventTime
    geom {
      ... on Point {
        coordinates
        type
      }
    }
    shipment {
      id
      idInternal
      status
    }
  }
}
    `;

/**
 * __useOnShipmentGpsEventAddedSubscription__
 *
 * To run a query within a React component, call `useOnShipmentGpsEventAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnShipmentGpsEventAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnShipmentGpsEventAddedSubscription({
 *   variables: {
 *      shipmentId: // value for 'shipmentId'
 *   },
 * });
 */
export function useOnShipmentGpsEventAddedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnShipmentGpsEventAddedSubscription, OnShipmentGpsEventAddedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnShipmentGpsEventAddedSubscription, OnShipmentGpsEventAddedSubscriptionVariables>(OnShipmentGpsEventAddedDocument, options);
      }
export type OnShipmentGpsEventAddedSubscriptionHookResult = ReturnType<typeof useOnShipmentGpsEventAddedSubscription>;
export type OnShipmentGpsEventAddedSubscriptionResult = Apollo.SubscriptionResult<OnShipmentGpsEventAddedSubscription>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GeoJson": [
      "LineString",
      "Point"
    ],
    "GeoObject": [
      "LineString",
      "Point"
    ],
    "Node": [
      "FavoriteShipment",
      "Location",
      "Order",
      "Shipment",
      "User"
    ]
  }
};
      export default result;
    