import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAlert from "../../components/useAlert";
import { useDeleteUserMutation, useUserQuery } from "../../generated/graphql";

const UserDelete: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();

  const alert = useAlert();

  const { loading, error, data } = useUserQuery({
    variables: {
      id: id!
    }
  });

  const [deleteUserMutation] = useDeleteUserMutation({
    variables: {
      clientMutationId: "1",
      id: id!
    },
    onCompleted: (response) => {
      if (response.deleteUser?.user?.successful) {
        alert.success(`User ${response.deleteUser.user.result?.email} has been deleted successfully.`);
        navigate("/users");
      } else {
        let errors = response.deleteUser?.user?.messages;
        alert.failure(`User ${response.deleteUser?.user?.result?.email} could not be deleted.`);
        console.error(errors);
      }
    },
    onError: (e) => {
      alert.failure(e.message);
      console.error(e);
    },
    refetchQueries: [
      'Users'
    ]
  });

  if (loading || error || !data) {
    return <div>Loading...</div>;
  }

  return <Transition.Root show={true} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={() => navigate('/users')}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  {/* <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" /> */}
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Delete user {data.user?.firstName} {data.user?.lastName}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete the account <strong>{data.user?.email}?</strong><br />
                      All of your data will be permanently removed from our servers forever. This action cannot be undone.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => deleteUserMutation()}
                >
                  Delete user
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => navigate("/users")}
                >
                  Cancel
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>;
};

export default UserDelete;