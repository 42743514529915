import moment from "moment";
import { NavLink, Outlet } from "react-router-dom";
import { useUsersQuery } from "../../generated/graphql";

const UserIndex: React.FC = (): JSX.Element => {
  const { loading, error, data } = useUsersQuery({
    variables: {
      first: 200
    }
  });

  if (loading || error || !data) {
    return <div>Loading...</div>;
  }

  return <main className="flex-1 bg-gray-100 flex">
    <Outlet />

    <div className="px-6 pt-6 lg:container">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Users</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <NavLink to="/users/add"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add user
          </NavLink>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Email
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Created at
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Updated at
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Confirmed at
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data.users?.edges?.map((edge) => {
                    const { id, firstName, lastName, email, insertedAt, updatedAt, confirmedAt } = { ...edge?.node };

                    return <tr key={edge?.node?.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {firstName} {edge?.node?.lastName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{email}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{insertedAt && moment(insertedAt).calendar()}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{updatedAt && moment(updatedAt).calendar()}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{confirmedAt && moment(confirmedAt).calendar()}</td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <NavLink to={`${id}/delete`} className="text-gray-600 hover:text-red-900 mr-2">
                          Delete<span className="sr-only">, {firstName} {lastName}</span>
                        </NavLink>

                        <NavLink to={`${id}/edit`} className="text-indigo-600 hover:text-indigo-900">
                          Edit<span className="sr-only">, {firstName} {lastName}</span>
                        </NavLink>
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>;
};

export default UserIndex;