import { MapPinIcon, TruckIcon, ExclamationCircleIcon, MapIcon } from '@heroicons/react/24/outline';
import {
  FunnelIcon, MagnifyingGlassIcon, XMarkIcon
} from '@heroicons/react/20/solid';
import moment from 'moment';
import React from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, Outlet, useMatch, useParams, useResolvedPath, useSearchParams } from 'react-router-dom';
import Badge from '../components/Badge';
import ShipmentListElement from '../components/ShipmentListElement';
import { ShipmentListItemFragment, ShipmentWithCarrierFragment, ShipmentWithFavoriteFragment, ShipmentWithOrderFragment, Status, useShipmentQuery, useShipmentsQuery } from '../generated/graphql';

const Shipments: React.FC = (): JSX.Element => {
  const { id: currentShipmentId } = useParams();
  const [searchParams] = useSearchParams();
  const isExpanded = !!currentShipmentId && !!searchParams.get('expanded');

  const { register, handleSubmit, getValues } = useForm();

  const onSubmit = (formData: any) => {
    refetch({
      ...formData,
      // status 'All' does not exist, thus, map to undefined.
      status: formData.status === 'All' ? undefined : formData.status
    });
  };


  const { refetch, loading, error, data, fetchMore } = useShipmentsQuery({
    variables: {
      status: Status.InTransit
    }
  });

  if (loading || error || !data) {
    return <div>Loading...</div>;
  }

  const nodes = data.paginateShipments?.edges?.map((edge) => edge?.node);

  return (
    <>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
        {/* Start main area*/}
        <div className="absolute inset-0">
          <Outlet />
        </div>
        {/* End main area */}
      </main>
      <aside className="hidden relative xl:order-first xl:flex xl:flex-col flex-shrink-0 w-128 border-r border-gray-200 overflow-y-auto">
        {/* Start secondary column (hidden on smaller screens) */}
        {isExpanded ?
          <div className="px-6 pt-6 pb-4">
            <ShipmentDetails shipment_id={currentShipmentId} />
          </div> :
          <>
            <div className="px-6 pt-6 pb-4">
              <h2 className="text-lg font-medium text-gray-900">Shipments</h2>
              <p className="mt-1 text-sm text-gray-600">Search for shipments</p>
              <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex space-x-4">
                  <div className="min-w-0">
                    <label htmlFor="filter" className="sr-only">Search</label>
                    <div className="relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
                      </div>
                      <input
                        type="search"
                        id="filter-shipment-id"
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                        placeholder="Shipment ID"
                        {...register("filter")}
                      />
                    </div>
                  </div>
                  <div>
                    <select
                      id="status"
                      className="shadow-sm w-full py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      defaultValue={Status.InTransit}
                      {...register("status")}
                    >
                      <option value="All">all</option>
                      <option value={Status.Initial}>initial</option>
                      <option value={Status.InTransit}>in transit</option>
                      <option value={Status.Delivered}>delivered</option>
                    </select>
                  </div>
                </div>

                <div className="flex space-x-4 mt-4">
                  <div className="relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <MapPinIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="search"
                      id="filter-origin"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                      placeholder="Origin"
                      {...register("origin")}
                    />
                  </div>

                  <div className="relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <MapPinIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="search"
                      id="filter-destination"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                      placeholder="Destination"
                      {...register("destination")}
                    />
                  </div>

                  <button type="submit" className="inline-flex justify-center px-3.5 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <FunnelIcon className="h-5 w-5 text-gray-400" />
                    <span className="sr-only">Filter</span>
                  </button>
                </div>
              </form>
            </div>

            <nav className="flex-1 min-h-0 overflow-y-auto" aria-label="Shipments">
              {nodes && nodes.length > 0 ?
                <div className="relative">
                  <ul className="relative z-0 divide-y divide-gray-200">
                    {nodes?.map((node) =>
                      node != null
                        ?
                        <ShipmentListItemElementActive
                          key={`shipments-${node.id}`}
                          shipment={node}
                        />
                        : null
                    )}
                  </ul>
                </div>
                :
                <div className="px-6 pt-3">
                  <h3 className="mt-2 text-sm font-medium text-gray-900">Sorry, no shipments found</h3>
                  <p className="mt-1 text-sm text-gray-500">Please verify your search criteria.</p>
                </div>
              }
            </nav>

            <div className="flex-shrink-0 p-4 border-t pt-[18px] border-gray-200">
              <div className="flex sm:justify-center">
                <button onClick={() => fetchMore({ variables: { after: data.paginateShipments?.pageInfo.endCursor, filter: getValues("filter"), status: getValues("status") } })}
                  disabled={!data.paginateShipments?.pageInfo.hasNextPage}
                  className="ml-3 relative inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:bg-gray-50">
                  Load More ...
                </button>
              </div>
            </div>
          </>}
        {/* End secondary column */}
      </aside>
    </>
  )
}

const ShipmentListItemElementActive = (props: { shipment: ShipmentListItemFragment & ShipmentWithCarrierFragment & ShipmentWithOrderFragment & ShipmentWithFavoriteFragment }) => {
  const resolved = useResolvedPath(props.shipment.id);
  const match = useMatch({ path: resolved.pathname, end: true });

  return <li className={`px-6 ${match ? "bg-gray-100" : ""}`}>
    <ShipmentListElement
      key={props.shipment.idInternal}
      shipment={props.shipment}
    />
  </li>
}

interface ShipmentDetailProps {
  shipment_id: string
}

const ShipmentDetails: React.FC<ShipmentDetailProps> = (props): JSX.Element => {
  const { loading, error, data } = useShipmentQuery({
    variables: {
      id: props.shipment_id
    }
  });

  const nrOfOrders = data?.shipment?.orders?.length || 0;
  const eta = data?.shipment?.eta?.estimatedArrivalTime;

  if (loading || error || !data) {
    return <div>Loading...</div>;
  }

  return <div>
    <div className="flex justify-between mb-4">
      <h2 className="text-lg font-medium text-gray-900">Shipment Details</h2>
      <NavLink
        to={`/shipments/${props.shipment_id}`}
        className="inline-flex rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 items-center gap-x-1.5"
      >
        <XMarkIcon className="w-4 h-4" />
      </NavLink>
    </div>

    <div>{data.shipment?.idInternal}</div>

    <div className="mb-1 flex space-x-2 items-center justify-between">
      <div className="flex space-x-2 items-center">
        <div>
          <TruckIcon className="h-4 w-4 text-gray-400 inline mr-1" />
          {data.shipment?.carrier && <small className="text-gray-500">by <strong>{data.shipment.carrier.name}</strong></small>}
          {/* FIXME: Display Arrive as default if no carrier is given - this is used as a workaround until we have the carrier populated. */}
          {!data.shipment?.carrier && <small className="text-gray-500">by <strong>Arrive</strong></small>}
        </div>
        <span aria-hidden="true">&middot;</span>
        <div>
          <small className="text-gray-500">{nrOfOrders} {nrOfOrders === 1 ? "order" : "orders"}</small>
        </div>
        {eta &&
          <>
            <span aria-hidden="true">&middot;</span>
            <div>
              <small className="text-gray-500">
                ETA{' '}
                <time title={moment(eta).format()} dateTime={moment(eta).format()}>{moment(eta).isBefore(moment()) ? moment(eta).format() : moment(eta).fromNow()}</time>
              </small>
            </div>
          </>
        }
      </div>

      {data.shipment?.status && <Badge status={data.shipment.status} />}
    </div>

    <div className="flow-root mt-8">
      <ul className="-mb-8">
        {data.shipment?.events?.slice().reverse().map((event, eventIdx) => (
          <li key={`shipment-event-${data.shipment?.id}-${eventIdx}`}>
            <div className="relative pb-4">
              {eventIdx !== (data.shipment?.events?.length || 0) - 1 ? (
                <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex items-start space-x-3">
                <>
                  <div>
                    <div className="relative px-1">
                      {event?.reasonCode && event?.reasonCode !== 'NORMAL_STATUS' ?
                        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-red-50 ring-8 ring-white">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
                        </div>
                        :
                        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                          <MapPinIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </div>}
                    </div>
                  </div>
                  <div className="min-w-0 flex-1 py-1.5">
                    <div className="text-sm text-gray-500">
                      <span>Updated status to</span>{' '}
                      <span className="font-medium text-gray-900">{event?.statusCode}</span>{' '}
                      <time className="whitespace-nowrap" dateTime={event?.eventTime} title={event?.eventTime}>{moment(event?.eventTime).fromNow()}</time>
                    </div>

                    <div className="mt-2 text-sm text-gray-700">
                      <div className="flex gap-1.5 items-center">
                        <MapIcon className="w-4 h-4" />
                        <span>
                          {event?.locationInformation?.city_name}, {event?.locationInformation?.state_code}
                          {event?.locationInformation?.facility_name && <>{' '}at {event?.locationInformation?.facility_name}</>}
                        </span>
                      </div>

                      {event?.payload?.eta?.filter((eta: any) => eta).map((eta: any) => <p className="mt-1">
                        <strong>ETA</strong> at next stop <strong>{eta.next_stop?.city_name}, {eta.next_stop?.state_code} at {eta.next_stop?.facility_name}</strong> <span title={eta.etato_next_stop}>{moment(eta.etato_next_stop).isBefore(new Date()) ? `at ${eta.etato_next_stop}` : moment(eta.etato_next_stop).fromNow()}</span>
                      </p>)}

                      {event?.reasonCode && event?.reasonCode !== 'NORMAL_STATUS' &&
                        <p className="mt-1"><strong>Incident:</strong> {event?.reasonCode}
                        </p>
                      }
                    </div>
                  </div>
                </>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  </div>
}

export default Shipments;