import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition, relayStylePagination } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import React, { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/Auth';
import { ErrorBoundary } from './components/ErrorBoundary';
import MainLayout from './components/MainLayout';
import { AUTH_TOKEN } from './constants';
import Dashboard from './routes/dashboard';
import ForgotPassword from './routes/forgot-password';
import Login from './routes/login';
import ResetPassword from './routes/reset-password';
import SettingsIndex from './routes/settings/index';
import SettingsGeneral from './routes/settings/general';
import Shipment from './routes/shipment';
import Shipments from './routes/shipments';
import ShipmentsActive from './routes/shipments_active';
import UserIndex from './routes/users';
import UserAdd from './routes/users/add';
import UserDelete from './routes/users/delete';
import UserEdit from './routes/users/edit';
import { AlertProvider } from './components/AlertContext';
import Alert from './components/Alert';
import SettingsPassword from './routes/settings/password';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_ALDISPHERE_API_HTTP_ENDPOINT || 'http://localhost:4000/graphql',
});

const wsLink = new GraphQLWsLink(createClient({
  url: process.env.REACT_APP_ALDISPHERE_API_WS_ENDPOINT || 'ws://localhost:4000/graphql-ws',
  connectionParams: () => ({
    authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN),
  }),
}));


const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(AUTH_TOKEN);

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

// Chain the HTTP link and the authorization link.
const authedHttpLink = authLink.concat(httpLink);

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authedHttpLink,
);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        // enable relay style pagination for queries on paginateShipments, filtered by status, and first.
        paginateShipments: relayStylePagination(() => ["status", "first"]),
      },
    }
  }
});

const apolloClient = new ApolloClient({
  link: splitLink,
  cache: cache
});

const App: React.FC = (): JSX.Element => {
  return (
    <ApolloProvider client={apolloClient}>
      <Suspense fallback={'Loading...'}>
        <BrowserRouter>
          <ErrorBoundary>
            <AuthProvider>
              <AlertProvider>
                <Alert />
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password/:token" element={<ResetPassword />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/" element={<RequireAuth><MainLayout /></RequireAuth>}>
                    <Route path="users" element={<UserIndex />}>
                      <Route path="add" element={<UserAdd />} />
                      <Route path=":id/edit" element={<UserEdit />} />
                      <Route path=":id/delete" element={<UserDelete />} />
                    </Route>
                    <Route path="shipments" element={<Shipments />}>
                      <Route path=":id" element={<Shipment />} />
                      <Route index element={<ShipmentsActive />} />
                    </Route>
                    <Route path="settings" element={<SettingsIndex />}>
                      <Route path="general" element={<SettingsGeneral />} />
                      <Route path="password" element={<SettingsPassword />} />
                    </Route>
                    <Route index element={<Dashboard />} />
                  </Route>
                  {/* <Route path="/shipments/:idInternal" element={<Shipment />} /> */}
                </Routes>
              </AlertProvider>
            </AuthProvider>
            {/* <AuthProvider initialQueryRef={initialQueryRef}>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/" element={
                    <RequireAuth>
                      <MainLayout initialQueryRef={initialQueryRef} />
                    </RequireAuth>
                  }>
                    <Route path="shipments" element={<Shipments />}>
                      <Route path=":idInternal" element={<Shipment />} />
                      <Route index element={<ShipmentsActive />} />
                    </Route>
                  </Route>
                </Routes>
              </AuthProvider> */}
          </ErrorBoundary>
        </BrowserRouter>
      </Suspense>
    </ApolloProvider>
  )
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default App;