import { useForm } from "react-hook-form";
import useAlert from "../../components/useAlert";
import { useChangePasswordMutation, useGetUserQuery } from "../../generated/graphql";

const SettingsPassword: React.FC = (): JSX.Element => {
  const alert = useAlert();
  const { loading, data, error } = useGetUserQuery();
  const { register, handleSubmit, setError, getValues, formState: { errors } } = useForm();
  const [changePasswordMutation] = useChangePasswordMutation({
    refetchQueries: ["GetUser"]
  });

  const onSubmit = (formData: any) => {
    changePasswordMutation({
      variables: {
        currentPassword: formData.currentPassword,
        newPassword: formData.password,
        clientMutationId: "1",
        id: data!.me!.id
      },
      onError: (e) => {
        alert.failure(e.message);
      },
      onCompleted: (response) => {
        if (response.changePassword?.user?.successful) {
          alert.success("Your password has been updated successfully, you have to sign in again.");
          window.location.href = '/login';
        } else {
          alert.failure("Your password could not be updated.");
          let errors = response.changePassword?.user?.messages;
          errors?.forEach((e) => setError(e!.field!, { type: "custom", message: e!.message! }));
        }
      }
    })
  };

  if (loading || error || !data) {
    return <div>Loading...</div>;
  }


  return <>
    <form className="space-y-8 divide-y divide-gray-200 mt-10" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">Change password</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Use a secure password for your account.</p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="old-password" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Old password
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  type="password"
                  id="old-password"
                  className={`${errors.currentPassword && "border-red-600 "}} block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                  {...register("currentPassword")}
                />
                {errors.currentPassword && <span className="sm:text-xs text-red-600">{errors.currentPassword.message}</span>}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                New password
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  type="password"
                  id="new-password"
                  className={`${errors.password && "border-red-600 "}} block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                  {...register("password")}
                />
                {errors.password && <span className="sm:text-xs text-red-600">{errors.password.message}</span>}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Repeat new password
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  type="password"
                  id="repeat-new-password"
                  className={`${errors.repeatPassword && "border-red-600 "}} block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                  {...register("repeatPassword", {
                    validate: (value) => {
                      const { password } = getValues();
                      return password === value || "Passwords not equal";
                    }
                  })}
                />
                {errors.repeatPassword && <span className="sm:text-xs text-red-600">{errors.repeatPassword.message}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="reset"
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Reset
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Change password
          </button>
        </div>
      </div>
    </form>
  </>;
};

export default SettingsPassword;