import { createContext, useContext, useEffect, useState } from "react";
import { AUTH_TOKEN } from "../constants";
import { useGetUserQuery } from "../generated/graphql";

interface User {
  token: string;
  email: string;
  firstName: string;
  lastName: string;
}

interface AuthContextType {
  user: User | undefined;
  signin: (user: User, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

let AuthContext = createContext<AuthContextType>({ user: undefined, signin: () => { }, signout: () => { } });

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = useState<User | undefined>(undefined);

  // Set data.me as user state if data conditionally fires an effect due to a change.
  // This is necessary in order to set the state for an already logged in user after 
  // a browser refresh.
  const { data } = useGetUserQuery();

  useEffect(() => {
    if (!data || !data.me) {
      return;
    }
    const { email, firstName, lastName } = { ...data.me };
    setUser({ email, firstName, lastName, token: "" });
  }, [data]);

  const signin = (newUser: User, callback: VoidFunction) => {
    localStorage.setItem(AUTH_TOKEN, newUser.token);
    setUser(newUser);
    callback();
  };

  const signout = (callback: VoidFunction) => {
    localStorage.removeItem(AUTH_TOKEN);
    setUser(undefined);
    callback();
  };

  return <AuthContext.Provider value={{ user, signin, signout }}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}