import React from "react";
import { RRNLRequestError } from "react-relay-network-modern";

type Props = {}

type State = {
  hasError: boolean,
  error: string | undefined | RRNLRequestError
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error: string): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  render() {
    if (this.state.hasError) {
      if (this.state.error instanceof RRNLRequestError && this.state.error.name === "Unauthenticated") {
        console.warn("Unauthenticated, redirecting to login page...");
        window.location.replace("/login");
        return;
      }

      return <div>An error occurred</div>;
    }

    return this.props.children;
  }
}