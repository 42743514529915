import { CheckCircleIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Status } from '../generated/graphql';

interface Props {
  status: Status
}

const Badge: React.FC<Props> = (props): JSX.Element =>
  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${statusToCssClasses(props.status)}`}>
    {props.status === Status.Delivered && <CheckCircleIcon className="w-4 h-4 mr-1 text-green-800 border rounded-full" />}
    {props.status.toLowerCase().replace("_", " ").toUpperCase()}
  </span>;

const statusToCssClasses = (status: Status): string => {
  switch (status) {
    case Status.InTransit:
      return "bg-blue-100 text-blue-800";
    case Status.Delivered:
      return "bg-green-100 text-green-800";
  }
  return "bg-gray-200 text-gray-800";
}

export default Badge;