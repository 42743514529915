import { useForm } from "react-hook-form";
import useAlert from "../../components/useAlert";
import { useGetUserQuery, useUpdateUserMutation } from "../../generated/graphql";

const SettingsGeneral: React.FC = (): JSX.Element => {
  const alert = useAlert();
  const { loading, data, error } = useGetUserQuery();
  const { register, handleSubmit, setError, formState: { errors } } = useForm();
  const [updateUserMutation] = useUpdateUserMutation();
  const onSubmit = (formData: any) => {
    updateUserMutation({
      variables: {
        ...formData,
        clientMutationId: "1",
        id: data?.me?.id
      },
      onError: (e) => {
        alert.failure(e.message);
      },
      onCompleted: (response) => {
        if (response.updateUser?.user?.successful) {
          alert.success("Your user profile has been updated successfully.");
        } else {
          alert.failure("Your user profile could not be updated.");
          let errors = response.updateUser?.user?.messages;
          errors?.forEach((e) => setError(e!.field!, { type: "custom", message: e!.message! }));
        }
      }
    })
  };

  if (loading || error || !data) {
    return <div>Loading...</div>;
  }


  return <>
    <form className="space-y-8 divide-y divide-gray-200 mt-10" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Use a permanent address where you can receive mail.</p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                First name
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  type="text"
                  id="first-name"
                  autoComplete="given-name"
                  className={`${errors.firstName && "border-red-600 "}} block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                  defaultValue={data.me?.firstName}
                  {...register("firstName")}
                />
                {errors.firstName && <span className="sm:text-xs text-red-600">{errors.firstName.message}</span>}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Last name
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  type="text"
                  id="last-name"
                  autoComplete="family-name"
                  className={`${errors.lastName && "border-red-600 "}} block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                  defaultValue={data.me?.lastName}
                  {...register("lastName")}
                />
                {errors.lastName && <span className="sm:text-xs text-red-600">{errors.lastName.message}</span>}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Email address
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  id="email"
                  type="email"
                  autoComplete="email"
                  className={`${errors.email && "border-red-600 "}} block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                  defaultValue={data.me?.email}
                  {...register("email")}
                />
                {errors.email && <span className="sm:text-xs text-red-600">{errors.email.message}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="reset"
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Reset
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </>;
};

export default SettingsGeneral;