import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, NavLink, useLocation, useParams } from 'react-router-dom';
import aldiLogo from '../components/aldi-logo.png';
import { useAuth } from '../components/Auth';
import { useResetPasswordMutation } from '../generated/graphql';

const ResetPassword: React.FC = (): JSX.Element => {
  const { token } = useParams();
  const location = useLocation();

  // Recover "from" path when one has been redirected to login
  // see also state in <RequiredAuth />
  const state = location.state as any;
  const from = state?.from || "/";

  let [completed, setCompleted] = useState(false);
  let auth = useAuth();

  const [resetPasswordMutation, { loading, error }] = useResetPasswordMutation();

  const { register, handleSubmit, getValues, formState: { errors } } = useForm();
  const onSubmit = (formData: any) => {
    resetPasswordMutation({
      variables: {
        ...formData,
        clientMutationId: "1"
      },
      onCompleted: (response) => {
        if (response.resetPassword?.user?.successful) {
          setCompleted(true);
        }
      }
    })
  };

  if (auth.user) {
    console.info(`User already logged in, redirecting to ${from}`);
    return <Navigate to={from} replace />;
  }

  return (
    <>
      <div className="min-h-full flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-24 w-auto"
                src={aldiLogo}
                alt="Aldisphere"
              />
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Reset your password</h2>
            </div>

            <div className="mt-8">
              {error && <LoginError error={error.message} />}

              {completed ?
                <>
                  <div className="rounded-md p-4 border-green-200 border">
                    <div className="flex">
                      <div>
                        <p className="text-sm text-green-800">
                          Your password has been changed successfully.
                        </p>
                      </div>
                    </div>
                  </div>
                  <NavLink to="/login" className="font-medium text-indigo-600 hover:text-indigo-500 mt-6 block">
                    Sign in with your changed password now.
                  </NavLink>
                </>
                :
                <div className="mt-6">
                  <form method="POST" className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Reset token
                      </label>
                      <div className="mt-1">
                        <input
                          id="reset-token"
                          type="text"
                          className={`${errors.token ? "border-red-600" : "border-gray-300"} appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                          defaultValue={token}
                          {...register("token", { required: true })}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        New password
                      </label>
                      <div className="mt-1">
                        <input
                          id="new-password"
                          type="password"
                          className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${errors.email && "border-red-600 "}} `}
                          {...register("password", { required: true })}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Confirm new password
                      </label>
                      <div className="mt-1">
                        <input
                          id="confirm-password"
                          type="password"
                          className={`${errors.passwordConfirmed ? "border-red-600" : "border-gray-300"} appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                          {...register("passwordConfirmed", {
                            required: true, validate: (value) => {
                              const { password } = getValues();
                              return password === value || "Passwords must be equal.";
                            }
                          })}
                        />
                        {errors.passwordConfirmed && <span className="sm:text-xs text-red-600">{errors.passwordConfirmed.message}</span>}
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        disabled={loading}
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        {loading &&
                          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>}
                        Change password
                      </button>
                    </div>
                  </form>

                  <div className="text-sm mt-4">
                    <NavLink to="/forgot-password" className="font-medium text-indigo-600 hover:text-indigo-500">
                      No reset token yet?
                    </NavLink>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1 bg-gradient-to-r from-blue-100 via-red-100 to-orange-100">
          {/*<img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />*/}
        </div>
      </div >
    </>
  )
}

const LoginError = (props: { error: string }) => <div className="rounded-md bg-red-50 p-4">
  <div className="flex">
    <div className="ml-3">
      <h3 className="text-sm font-medium text-red-800">{props.error}</h3>
    </div>
  </div>
</div>


export default ResetPassword;