import { LatLngExpression } from 'leaflet';
import React from 'react';
import { MapContainer, ScaleControl, TileLayer } from 'react-leaflet';

interface Props {
  zoom: number,
  center: LatLngExpression
}

const Map: React.FC<Props> = (props): JSX.Element =>
  <MapContainer className='h-full w-full' center={props.center} zoom={props.zoom}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <ScaleControl />
    {props.children}
  </MapContainer>;


export default Map;