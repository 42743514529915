import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useAlert from "../../components/useAlert";
import { useCreateUserMutation } from "../../generated/graphql";

const UserAdd: React.FC = (): JSX.Element => {
  const alert = useAlert();

  const [createUserMutation] = useCreateUserMutation({
    refetchQueries: [
      'Users'
    ]
  });

  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, setError } = useForm();
  const onSubmit = (formData: any) => {
    createUserMutation({
      variables: {
        ...formData,
        clientMutationId: "1"
      },
      onError: (e) => {
        alert.failure(e.message);
        console.error(e);
      },
      onCompleted: (response) => {
        if (response.createUser?.user?.successful) {
          alert.success(`User ${response.createUser.user.result?.email} has been added successfully.`);
          navigate("/users");
        } else {
          let errors = response.createUser?.user?.messages;
          alert.failure(`User ${response.createUser?.user?.result?.email} could not be added.`);
          errors?.forEach((e) => setError(e!.field!, { type: "custom", message: e!.message! }));
        }
      }
    })
  };


  return <Transition.Root show={true} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={() => { navigate("/users"); return false; }}>
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl" onSubmit={handleSubmit(onSubmit)}>
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">Add User</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => navigate("/users")}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">
                          Update the information below and click save to add a new user.
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        <div className="space-y-6 pt-6 pb-5">
                          <div>
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-900">
                              First name
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                id="first-name"
                                className={`${errors.firstName && "border-red-600 "}} block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                                {...register("firstName")}
                              />
                              {errors.firstName && <span className="sm:text-xs text-red-600">{errors.firstName.message}</span>}
                            </div>
                          </div>
                          <div>
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-900">
                              Last name
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                id="last-name"
                                className={`${errors.lastName && "border-red-600 "}} block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                                {...register("lastName")}
                              />
                              {errors.lastName && <span className="sm:text-xs text-red-600">{errors.lastName.message}</span>}
                            </div>
                          </div>
                          <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-900">
                              Email
                            </label>
                            <div className="mt-1">
                              <input
                                type="email"
                                id="email"
                                className={`${errors.email && "border-red-600 "}} block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                                {...register("email")}
                              />
                              {errors.email && <span className="sm:text-xs text-red-600">{errors.email.message}</span>}
                            </div>
                          </div>
                          <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-900">
                              Password
                            </label>
                            <div className="mt-1">
                              <input
                                type="password"
                                id="email"
                                className={`${errors.password && "border-red-600 "}} block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                                {...register("password")}
                              />
                              {errors.password && <span className="sm:text-xs text-red-600">{errors.password.message}</span>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-shrink-0 justify-end px-4 py-4">
                    <button
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => navigate("/users")}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Dialog>
  </Transition.Root >;
};

export default UserAdd;