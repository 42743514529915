import { NavLink, Outlet, useLocation } from "react-router-dom";

const tabs = [
  { name: 'General', href: '/settings/general' },
  { name: 'Password', href: '/settings/password' }
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const SettingsIndex: React.FC = (): JSX.Element => {
  let location = useLocation();

  return <main className="flex-1 overflow-y-auto">
    <div className="relative mx-auto max-w-4xl md:px-8 xl:px-0">
      <div className="pt-10 pb-16">
        <div className="px-4 sm:px-6 md:px-0">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">Settings</h1>
        </div>
        <div className="px-4 sm:px-6 md:px-0">
          <div className="py-6">
            {/* Tabs */}
            <div className="lg:hidden">
              <label htmlFor="selected-tab" className="sr-only">
                Select a tab
              </label>
              <select
                id="selected-tab"
                name="selected-tab"
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.href === location.pathname)!.name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden lg:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8">
                  {tabs.map((tab) => (
                    <NavLink
                      to={tab.href}
                      key={tab.name}
                      className={({ isActive }) => classNames(
                        isActive
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                      )}
                    >
                      {tab.name}
                    </NavLink>
                  ))}
                </nav>
              </div>
            </div>

            <Outlet />
          </div>
        </div>
      </div>
    </div>
  </main>;
}

export default SettingsIndex;