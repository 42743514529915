import { DivIcon, PathOptions } from "leaflet";
import L from 'leaflet';
import aldiLogo from '../components/aldi-logo.png';

export const AUTH_TOKEN = "authToken";

export const MARKER_PING = new DivIcon({
  html: `<span class="flex h-3 w-3">
    <span class="animate-ping absolute inline-flex h-full w-full rounded-full" style="background-color: #001E5E"></span>
    <span class="relative inline-flex rounded-full h-3 w-3 border-2" style="background-color: #000"></span>
  </span>`,
  className: "bg-transparent"
});

export const MARKER_START = new DivIcon({
  html: `<span class="flex h-3 w-3 rounded-full" style="background-color: #001E5E"></span>`,
  className: "bg-transparent"
});

export const MARKER_END = new DivIcon({
  html: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-green-800 border rounded-full bg-green-100">
      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
    </svg>
  `,
  className: "bg-transparent"
});

export const MARKER_LOCATION = new DivIcon({
  html: `
    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" style="color: #001E5E" stroke="white">
      <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
    </svg>
  `,
  className: "bg-transparent"
});

export const MARKER_POSITION = new DivIcon({
  html: `<span class="flex h-1 w-1 rounded-full"></span>`,
  className: "bg-transparent"
});

export const MARKER_ERROR = new DivIcon({
  html: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-red-500 border rounded-full bg-white">
      <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
    </svg>
  `,
  className: "bg-transparent"
});

export const MARKER_ALDI = L.icon({
  iconUrl: aldiLogo,
  iconSize: [20, 24],
  className: 'leaflet-aldi-icon'
});

export const PATH_OPTIONS_DEFAULT: PathOptions = {
  color: "#001E5E",
  opacity: 0.65,
  stroke: true
}

export const PATH_OPTIONS_NEXT_STOP: PathOptions = {
  color: "#001E5E",
  opacity: 0.45,
  dashArray: '2, 8',
}