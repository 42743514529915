import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { Popup, useMap, GeoJSON, Marker, FeatureGroup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { OnShipmentGpsEventAddedDocument, OnShipmentGpsEventAddedSubscription, OnShipmentGpsEventAddedSubscriptionVariables, ShipmentQuery, ShipmentWithOrderFragment, Status, useShipmentQuery } from '../generated/graphql';
import { MARKER_ALDI, MARKER_END, MARKER_ERROR, MARKER_LOCATION, MARKER_PING, MARKER_POSITION, MARKER_START, PATH_OPTIONS_DEFAULT, PATH_OPTIONS_NEXT_STOP } from '../constants';
import Map from '../components/Map';
import moment from 'moment';


const Shipment: React.FC = (): JSX.Element => {
  let { id } = useParams();
  const { subscribeToMore, refetch, loading, error, data } = useShipmentQuery(
    { variables: { id: id } }
  );

  if (loading) return <div>Loading</div>;

  if (error || !data) {
    return (
      <div className="p-4">
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">{error?.message}</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <ShipmentMap
    data={data}
    subscribeToNewGpsEvents={() =>
      subscribeToMore<OnShipmentGpsEventAddedSubscription, OnShipmentGpsEventAddedSubscriptionVariables>({
        document: OnShipmentGpsEventAddedDocument,
        variables: { shipmentId: id! },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          console.log("Refetching shipment details after GPS update...");
          refetch();
          console.log("subscription data", subscriptionData.data);

          return prev;

          // TODO: merge GPS positions instead of refetching - data consistency?
          // const geomAdded: any = subscriptionData.data.gpsEventAdded?.geom;
          // let coordinates = prev.shipment?.geometry?.coordinates;
          // if (geomAdded && coordinates) {
          //   coordinates = [geomAdded.coordinates, ...coordinates];
          //   console.log("pushed to coordinates?", coordinates);
          // }

          // // merge GPS position of subscription and update query state
          // const updatedQueryState = Object.assign({}, prev, {
          //   shipment: {
          //     ...prev.shipment,
          //     geometry: {
          //       ...prev.shipment?.geometry,
          //       coordinates: coordinates
          //     }
          //   }
          // });

          // return updatedQueryState;
        }
      })
    } />;
}

const toPosition = (gpsEvent: any): any => {
  if (gpsEvent?.geom?.__typename !== "Point") {
    return [0, 0];
  }

  let [lat, lng] = gpsEvent?.geom?.coordinates;
  return [lng, lat];
}

const ShipmentMap: React.FC<{ subscribeToNewGpsEvents: any, data: ShipmentQuery }> = ({ subscribeToNewGpsEvents, data }): JSX.Element => {
  useEffect(() => {
    subscribeToNewGpsEvents();
  })

  return (
    <div className="h-full w-full flex">
      <Map center={[51.505, -0.09]} zoom={4}>
        <FeatureGroups data={data} />
      </Map>
    </div>
  );
}

const OrdersWithOriginsAndDestinations = (props: { shipment: ShipmentWithOrderFragment }): JSX.Element => {
  const orders = props.shipment.orders || [];

  // shipment has no orders
  if (orders.length <= 0) {
    return <></>;
  }

  // shipment has multiple orders
  const uniqueOrigins = Array.from(new Set(orders.map((order) => order?.origin)));
  const uniqueDestinations = Array.from(new Set(orders.map((order) => order?.destination)));

  return <>
    {uniqueOrigins.map((origin, index) =>
      <Marker icon={MARKER_LOCATION} position={toPosition(origin)} key={`origin-${index}`}>
        <Popup>
          <div>... via <strong>{origin?.name}</strong> ({origin?.type})</div>
        </Popup>
      </Marker>
    )}

    {uniqueDestinations.map((destination, index) =>
      <Marker icon={MARKER_ALDI} position={toPosition(destination)} key={`destination-${index}`}>
        <Popup>
          <div>... to <strong>{destination?.name}</strong> ({destination?.type})</div>
        </Popup>
      </Marker>
    )}
  </>;
}

const FeatureGroups: React.FC<{ data: ShipmentQuery }> = ({ data }): JSX.Element => {
  const map = useMap();
  const featureGroupRef: any = useRef();

  useEffect(function () {
    setTimeout(() => {
      const bounds = featureGroupRef.current.getBounds();
      if (bounds.isValid()) {
        map.flyToBounds(bounds, { maxZoom: 7, duration: 1.5 });
      }
    }, 50);
  });

  const toGeoJson = (geometry: any): any => (
    {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: geometry.type,
            coordinates: geometry.coordinates
          },
          properties: {
            isNextStop: false
          }
        }
      ]
    }
  );

  const geoJson = toGeoJson(data.shipment?.geometry);

  const geoJsonNextStop: any = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: data.shipment?.geometry?.type,
          coordinates: [
            data.shipment?.geometry?.coordinates?.at(-1),
            // find only events with ETA information and pick the last one, discard undefined or empty coordinates.
            data.shipment?.events?.filter((event: any) => event?.payload?.eta).at(-1)?.payload?.eta?.flatMap((eta: any) => [eta.next_stop?.longitude, eta.next_stop?.latitude].filter(c => !!c))
          ].filter(c => !!c && c.length > 0),
        },
      }
    ]
  }

  const geoJsonLayerKey = `${data.shipment?.idInternal}-${data.shipment?.geometry?.coordinates?.length}`;

  return <FeatureGroup ref={featureGroupRef}>
    <GeoJSON data={geoJson} pathOptions={PATH_OPTIONS_DEFAULT} key={geoJsonLayerKey} />

    {data.shipment?.status !== Status.Delivered && <GeoJSON data={geoJsonNextStop} pathOptions={PATH_OPTIONS_NEXT_STOP} key={`${geoJsonLayerKey}-next-stop`} />}


    {data.shipment?.events?.map((gpsEvent, index, { length }) => {
      const isFirstGpsEvent = index === 0;
      const isLatestGpsEvent = index + 1 === length;

      if (isFirstGpsEvent) {
        return <Marker icon={MARKER_START} position={toPosition(gpsEvent)} key={index}>
          <Popup>
            <div>Shipment departed at {moment(gpsEvent?.eventTime).fromNow()} at</div>
            <div className="border-b mb-2 pb-2" title={gpsEvent?.eventTime}>{moment(gpsEvent?.eventTime).calendar()}.</div>
            <div><strong>Status Code:</strong> {gpsEvent?.statusCode}</div>
            {gpsEvent?.reasonCode && <div>{gpsEvent.reasonCode}</div>}
          </Popup>
        </Marker>
      }

      if (isLatestGpsEvent && data.shipment?.status !== Status.Delivered) {
        return <Marker icon={MARKER_PING} position={toPosition(gpsEvent)} key={index}>
          <Popup>
            <div>Current position reported {moment(gpsEvent?.eventTime).fromNow()} at</div>
            <div className="border-b mb-2 pb-2" title={gpsEvent?.eventTime}>{moment(gpsEvent?.eventTime).calendar()}.</div>
            <div><strong>Status Code:</strong> {gpsEvent?.statusCode}</div>
            {gpsEvent?.reasonCode && <div>{gpsEvent.reasonCode}</div>}
          </Popup>
        </Marker>
      }

      if (isLatestGpsEvent && data.shipment?.status === Status.Delivered) {
        return <Marker icon={MARKER_END} position={toPosition(gpsEvent)} key={index}>
          <Popup>
            <div>Delivered {moment(gpsEvent?.eventTime).fromNow()} at</div>
            <div className="border-b mb-2 pb-2" title={gpsEvent?.eventTime}>{moment(gpsEvent?.eventTime).calendar()}.</div>
            <div><strong>Status Code:</strong> {gpsEvent?.statusCode}</div>
            {gpsEvent?.reasonCode && <div>{gpsEvent.reasonCode}</div>}
          </Popup>
        </Marker>
      }

      if (gpsEvent?.reasonCode && gpsEvent.reasonCode !== "NORMAL_STATUS") {
        return <Marker icon={MARKER_ERROR} position={toPosition(gpsEvent)} key={index}>
          <Popup>
            <div>Shipment reported a possible delay at</div>
            <div className="border-b mb-2 pb-2" title={gpsEvent?.eventTime}>{moment(gpsEvent?.eventTime).calendar()}.</div>
            <div><strong>Status Code:</strong> {gpsEvent?.statusCode}</div>
            <div><strong>Reason:</strong> {gpsEvent.reasonCode}</div>
          </Popup>
        </Marker>
      }

      return <Marker icon={MARKER_POSITION} position={toPosition(gpsEvent)} key={index}>
        <Popup>
          <div>Shipment seen at this position {moment(gpsEvent?.eventTime).fromNow()} at</div>
          <div className="border-b mb-2 pb-2" title={gpsEvent?.eventTime}>{moment(gpsEvent?.eventTime).calendar()}.</div>
          <div><strong>Status Code:</strong> {gpsEvent?.statusCode}</div>
        </Popup>
      </Marker>
    })}

    {data.shipment &&
      <>
        <OrdersWithOriginsAndDestinations shipment={data.shipment} />
      </>
    }
  </FeatureGroup>
}

export default Shipment;