import { EyeIcon, StarIcon as StarIconOutline, TruckIcon } from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/20/solid';
import moment from 'moment';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { ShipmentListItemFragment, ShipmentWithCarrierFragment, ShipmentWithEtaFragment, ShipmentWithFavoriteFragment, ShipmentWithOrderFragment, useCreateFavoriteShipmentMutation, useDeleteFavoriteShipmentMutation } from '../generated/graphql';
import Badge from './Badge';
import useAlert from './useAlert';

interface Props {
  shipment: ShipmentListItemFragment & ShipmentWithCarrierFragment & ShipmentWithOrderFragment & ShipmentWithFavoriteFragment & ShipmentWithEtaFragment
}

const ShipmentListElement: React.FC<Props> = (props) => {
  const alert = useAlert();
  const [createFavoriteShipmentMutation] = useCreateFavoriteShipmentMutation({
    variables: {
      clientMutationId: "1",
      shipmentId: props.shipment.id
    },
    onError: (e) => {
      alert.failure(e.message);
    },
    onCompleted: (response) => {
      if (response.createFavoriteShipment?.favoriteShipment?.successful) {
        alert.success(`Shipment "${props.shipment.idInternal}" has been added to your favorites.`);
      } else {
        alert.failure(`Sorry, shipment "${props.shipment.idInternal}" could not be added to your favorites.`)
        console.error(response.createFavoriteShipment?.favoriteShipment?.messages);
      }
    },
    refetchQueries: ["Shipments", "Dashboard"],
    awaitRefetchQueries: true
  });

  const [deleteFavoriteShipmentMutation] = useDeleteFavoriteShipmentMutation({
    onError: (e) => {
      alert.failure(e.message);
    },
    onCompleted: (response) => {
      if (response.deleteFavoriteShipment?.favoriteShipment?.successful) {
        alert.success(`Shipment "${props.shipment.idInternal}" has been deleted from your favorites.`);
      } else {
        alert.failure(`Sorry, shipment "${props.shipment.idInternal}" could not be deleted from your favorites.`)
        console.error(response.deleteFavoriteShipment?.favoriteShipment?.messages);
      }
    },
    refetchQueries: ["Shipments", "Dashboard"],
    awaitRefetchQueries: true
  });

  const nrOfOrders = props.shipment.orders?.length || 0;
  const eta = props.shipment.eta?.estimatedArrivalTime;

  return <>
    <div className="py-3">
      <div className="flex">
        <div className="flex-grow">
          <NavLink to={`/shipments/${props.shipment.id}`}>
            {props.shipment.idInternal}
          </NavLink>
        </div>
        <div className="flex gap-2 items-center">
          <Badge status={props.shipment.status} />

          {props.shipment.favorite?.id ?
            <button
              type="button"
              className="relative rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => deleteFavoriteShipmentMutation({
                variables: {
                  clientMutationId: "1",
                  favoriteShipmentId: props.shipment.favorite!.id
                }
              })}
              title="Unmark favorite"
            >
              <StarIconSolid className="text-yellow-500 hover:text-yellow-600 h-5 w-5" />
            </button>
            :
            <button
              type="button"
              className="relative rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => createFavoriteShipmentMutation()}
              title="Mark as favorite"
            >
              <StarIconOutline className="text-yellow-500 hover:text-yellow-600 h-5 w-5" />
              {/* <StarIconOutline className="text-yellow-500 hover:text-yellow-600 h-5 w-5" /> */}
            </button>
          }

          <NavLink to={`/shipments/${props.shipment.id}?expanded=true`} title="Show shipment details">
            <EyeIcon className="h-5 w-5 text-gray-500" />
          </NavLink>
        </div>
      </div>

      <div className="flex space-x-2 mb-1">
        <div>
          <TruckIcon className="h-4 w-4 text-gray-400 inline mr-1" />
          {props.shipment.carrier && <small className="text-gray-500">by <strong>{props.shipment.carrier.name}</strong></small>}
          {/* FIXME: Display Arrive as default if no carrier is given - this is used as a workaround until we have the carrier populated. */}
          {!props.shipment.carrier && <small className="text-gray-500">by <strong>Arrive</strong></small>}
        </div>
        <span aria-hidden="true">&middot;</span>
        <div>
          <small className="text-gray-500">{nrOfOrders} {nrOfOrders === 1 ? "order" : "orders"}</small>
        </div>
        {eta &&
          <>
            <span aria-hidden="true">&middot;</span>
            <div>
              <small className="text-gray-500">
                ETA{' '}
                <time title={moment(eta).format()} dateTime={moment(eta).format()}>{moment(eta).isBefore(moment()) ? moment(eta).format() : moment(eta).fromNow()}</time>
              </small>
            </div>
          </>
        }
      </div>

      <ShipmentListElementOrders shipment={props.shipment} />
    </div>
  </>
}

const ShipmentListElementOrders = (props: { shipment: ShipmentListItemFragment & ShipmentWithOrderFragment }) => {
  const orders = props.shipment.orders || [];

  // shipment has no orders
  if (orders.length <= 0) {
    return <></>;
  }

  // shipment has multiple orders
  const uniqueOrigins = Array.from(new Set(orders.map((order) => order?.origin)));
  const uniqueDestinations = Array.from(new Set(orders.map((order) => order?.destination)));

  return <ul className="mt-4">
    <li key={`${props.shipment.id}-from`}>
      <div className="relative pb-4">
        <span className="absolute top-2 left-1 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
        <div className="relative flex space-x-3">
          <div>
            <span className="h-2 w-2 rounded-full bg-gray-400 flex items-center justify-center ring-2 ring-white">

            </span>
          </div>
          <div className="min-w-0 flex-1 -mt-1.5 flex justify-between space-x-4">
            <div>
              <p className="text-sm text-gray-500">via{' '}
                {uniqueOrigins.map((origin, idx) =>
                  <span
                    className="font-medium text-gray-900"
                    key={`${props.shipment.id}-origins-${origin?.id}`}
                  >
                    {origin?.name || "Unknown"}{idx < uniqueOrigins.length - 1 && ', '}
                  </span>)}
              </p>
            </div>
            <div className="text-right text-sm whitespace-nowrap text-gray-500">
              {props.shipment.startTimeActual && <time title={props.shipment.startTimeActual} dateTime={props.shipment.startTimeActual}>{moment(props.shipment.startTimeActual).fromNow()}</time>}
            </div>
          </div>
        </div>
      </div>
    </li>
    <li key={`${props.shipment.id}-to`}>
      <div className="relative">
        <div className="relative flex space-x-3">
          <div>
            <span className="h-2 w-2 rounded-full bg-gray-400 flex items-center justify-center ring-2 ring-white">

            </span>
          </div>
          <div className="min-w-0 flex-1 -mt-1.5 flex justify-between space-x-4">
            <div>
              <p className="text-sm text-gray-500">to{' '}
                <span className="font-medium text-gray-900">
                  {uniqueDestinations.map((destination, idx) =>
                    <span
                      className="font-medium text-gray-900"
                      key={`${props.shipment.id}-destinations-${destination?.id}`}
                    >
                      {destination?.name || "Unknown"}{idx < uniqueDestinations.length - 1 && ', '}
                    </span>)}
                </span>
              </p>
            </div>
            <div className="text-right text-sm whitespace-nowrap text-gray-500">
              {props.shipment.endTimeActual && <time title={props.shipment.endTimeActual} dateTime={props.shipment.endTimeActual}>{moment(props.shipment.endTimeActual).fromNow()}</time>}
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>;
}

export default ShipmentListElement;